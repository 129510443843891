import Footer from "./component/footer"
import NavMenu from "./component/navMenu"
import React from "react";

function Contact() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}

function Content() {

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    return (
        <div className='row' style={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden', backgroundColor: '#37283D' }}>
            <div className="col text-white h-100 d-flex flex-column justify-content-around">

                <div className="d-flex justify-content-center">
                    <div className="row w-50" >
                        <div className="col-12">
                            <h1 className="text-white pb-4">CONTACT ME</h1>
                        </div>
                        <div className="col">
                            <a href="https://instagram.com/wolfshira.png" target="_blank">
                                <img src="./art/instagram.png" style={{ height: '10vh' }} />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://twitter.com/wolfshira_png" target="_blank">
                                <img src="./art/twitter.png" style={{ height: '10vh' }} />
                            </a>
                        </div>
                        <div className="col">
                            <a href="https://wolfshira.itch.io/" target="_blank">
                                <img src="./art/itch.png" style={{ height: '10vh' }} />
                            </a>
                        </div>
                        <div className="col-12 text-center">
                            <h5 className="pt-5">My email: wolfshira.art@gmail.com</h5>

                        </div>
                    </div>
                </div>


                <h5><b>I’m not interested in NFTs or AI projects. Go away.</b></h5>

            </div>
        </div >
    )

}

export default Contact  