const vg = [
    {
        image: './art/breakthecycle_banner.png',
        title: 'breakthecycle',
        description: 'Lorem ipsum dolor sit amet',
        url: 'https://wolfshira.itch.io/break-the-cycle'
    },
    {
        image: './art/thumbnail.png',
        title: 'breakthecycle',
        description: 'Lorem ipsum dolor sit amet',
        url: 'https://wolfshira.itch.io/healing-potion'
    }
]
export default vg