
import Nav from 'react-bootstrap/Nav';
import { useNavigate } from 'react-router-dom';

function NavMenu() {
    const navigate = useNavigate()

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    // <h2 className='text-white py-2 '>WOLFSHIRA</h2>

    return (
        <div style={{ height: '20vh', backgroundColor: '#2F2332', zIndex: '1' }} className='d-flex flex-column justify-content-center'>
            <a style={{ cursor: 'pointer' }} onClick={() => navigate('/')} ><h1 style={{ color: 'white', padding: 0, margin: 0 }}>WOLFSHIRA</h1></a>

            <Nav className=' justify-content-center pt-2'   >
                <Nav.Item className='mx-3 text-white' onClick={() => navigate('/')} style={{ cursor: 'pointer', fontSize: '1.2em' }}>
                    Home
                </Nav.Item>
                <Nav.Item className=' mx-3 text-white' onClick={() => navigate('/portfolio')} style={{ cursor: 'pointer', fontSize: '1.2em' }} >
                    Portfolio
                </Nav.Item>
                <Nav.Item className=' mx-3 text-white' onClick={() => navigate('/commissions')} style={{ cursor: 'pointer', fontSize: '1.2em' }} >
                    Commissions
                </Nav.Item>
                <Nav.Item className=' mx-3 text-white' onClick={() => navigate('/about')} style={{ cursor: 'pointer', fontSize: '1.2em' }}>
                    About Me
                </Nav.Item>
                <Nav.Item className=' mx-3 text-white' onClick={() => navigate('/contact')} style={{ cursor: 'pointer', fontSize: '1.2em' }} >
                    Contact
                </Nav.Item>
            </Nav>
        </div>
    );
}
export default NavMenu