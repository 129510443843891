import NavMenu from "./component/navMenu"

import React from "react";

import Footer from "./component/footer";
import { useNavigate } from "react-router-dom";


//   <img src="./art/sfondo.png" />

//                        <h1 className="p-0 m-0" style={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.25)', width: '25%', border: '2px solid white' }}>PORTFOLIO</h1>

function Homepage() {

    const navigate = useNavigate()

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    const portfolio = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: 'white',
        backgroundColor: 'rgba(102, 68, 120, 0.83)',
        border: '3px solid white',
        borderRadius: '3px'
    }

    return (
        <div style={{ height: '100vh', backgroundColor: '#2F2332', overflow: 'hidden', zIndex: 0 }} className='App'>
            <NavMenu />

            <div className="w-100" style={{ height: '70vh', backgroundImage: 'url(./art/sfondo2.jpg)', backgroundSize: 'cover' }} >

                <div className="h-100 d-flex flex-column justify-content-center"  >
                    <div style={{ cursor: 'pointer' }} className="d-flex justify-content-center" onClick={() => navigate('/portfolio')}>
                        <h3 className="px-5 py-2" style={portfolio}>PORTFOLIO</h3>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    )
}


export default Homepage