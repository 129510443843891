
import NavMenu from './component/navMenu';
import Footer from './component/footer'
import { useNavigate } from 'react-router-dom';


function Portfolio() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} className='App'>
            <NavMenu />

            <Content />

            <Footer />
        </div>
    );
}

//https://www.npmjs.com/package/react-hover-video-player



function Content() {
    const navigate = useNavigate()

    return (
        <div className='row px-5' style={{ height: '70vh', backgroundColor: '#37283D' }}>

            <div className='col-12 col-lg px-4 pb-1' >
                <div onClick={() => navigate("/portfolio/2DArt")} className=' h-100' style={{ cursor: 'pointer', backgroundImage: 'url(./art/2d.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='text-white d-flex flex-column h-100 justify-content-end'>
                        <h1>2D ART</h1>
                    </div>
                </div>
            </div>

            <div className='col-12 col-lg px-4 pb-1' >
                <div onClick={() => navigate("/portfolio/3DArt")} className=' h-100' style={{ cursor: 'pointer', backgroundImage: 'url(./art/3d.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='text-white d-flex flex-column h-100 justify-content-end'>
                        <h1>3D ART</h1>
                    </div>
                </div>
            </div>

            <div className='col-12 col-lg px-4 pb-1' >
                <div onClick={() => navigate("/portfolio/videogames")} className=' h-100' style={{ cursor: 'pointer', backgroundImage: 'url(./art/videogame.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='text-white d-flex flex-column h-100 justify-content-end'>
                        <h1>VIDEOGAMES</h1>
                    </div>
                </div>
            </div>

        </div >
    )

}

export default Portfolio;
