
import React from "react";
import { useNavigate } from "react-router-dom";


function ImageViewer() {
    const navigate = useNavigate()

    let image = JSON.parse(sessionStorage.getItem('SelectedImage'))

    return (
        <div className="d-flex flex-column justify-content-center App p-5" style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} >
            <div className="text-white text-start p-5" style={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer' }} onClick={() => navigate(-1)}>
                <img src="./art/close.png" style={{ width: '4vw' }} />
            </div>

            <div className="align-center d-flex flex-column justify-content-center " >
                <div className="d-flex justify-content-center">
                    <img src={image.image} className="h-100" style={{ maxHeight: '90vh', maxWidth: '90vw' }} />
                </div>
            </div>
        </div>
    )
}

/*
<div style={{height: '10vh'}}>
                        <h4 className="text-start p-2 text-white" style={{cursor: 'pointer'}} onClick={() => navigate(-1)}>torna alla scheda </h4>
                    </div>

*/

export default ImageViewer