import Footer from "./component/footer"
import NavMenu from "./component/navMenu"
import React from "react";

function Commissions() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflow: 'hidden' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}

function Content() {

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    return (
        <div className='row' style={{ height: '70vh', overflowY: 'auto', overflowX: 'hidden', backgroundColor: '#37283D' }}>
            <div className="col h-100 d-flex flex-column justify-content-center">
                <h1 className="text-white">COMING SOON...</h1>
            </div>
        </div >
    )

}

export default Commissions