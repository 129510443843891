import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter, Route, Routes } from 'react-router-dom'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Portfolio from './page/portfolio';
import Homepage from './page/homepage'
import About from './page/about'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Art2d from './page/portfolio/Art2d';
import Art3d from './page/portfolio/Art3d';
import Commissions from './page/commissions';
import Contact from './page/contact';
import Project from './page/portfolio/ProjectPage';
import ImageViewer from './page/portfolio/imageViewer';

import Videogames from './page/portfolio/videogames';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <Homepage />
            </div>
          } />
        <Route
          path="/portfolio"
          element={
            <div>
              <Portfolio />
            </div>
          } />
        <Route
          path="portfolio/2DArt"
          element={
            <div>
              <Art2d />
            </div>
          } />
        <Route
          path="portfolio/3DArt"
          element={
            <div>
              <Art3d />
            </div>
          } />
        <Route
          path="portfolio/videogames"
          element={
            <div>
              <Videogames />
            </div>
          } />
        <Route
          path="/about"
          element={
            <div>
              <About />
            </div>
          } />
        <Route
          path="/commissions"
          element={
            <div>
              <Commissions />
            </div>
          } />
        <Route
          path="/contact"
          element={
            <div>
              <Contact />
            </div>
          } />
        <Route
          path="/portfolio/project"
          element={
            <div>
              <Project />
            </div>
          } />
        <Route
          path="/portfolio/project/image"
          element={
            <div>
              <ImageViewer />
            </div>
          } />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
