
import { useNavigate } from "react-router-dom";
import Footer from "../component/footer"
import NavMenu from "../component/navMenu"
import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
//import ImageGallery from "react-image-gallery";
//import "react-image-gallery/styles/css/image-gallery.css";


function Project() {
    return (
        <div style={{ height: '100vh', backgroundColor: '#131419', overflowX: 'hidden' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}

function Content() {

    const navigate = useNavigate()

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };

    let project = JSON.parse(sessionStorage.getItem('selectedProject'))

    function otherImages(img) {

        let output = []
        if (img.otherImages != null) {

            img.otherImages.map(
                img => {
                    //output.push((<MyModal image={img.image} description="lol" duration={0.5} />))
                    output.push((<img className="w-100 my-3" src={img.image} onClick={() => {
                        sessionStorage.setItem('SelectedImage', JSON.stringify(img))
                        navigate('/portfolio/project/image')
                    }} />))
                }
            )
        }
        return output
    }



    return (
        <div className='' style={{ minHeight: '70vh', backgroundColor: '#37283D' }}>

            <div style={{ objectFit: 'cover', height: '10vh', backgroundImage: "url('./art/2d.jpg')" }}>
                <div style={centerMiddle}>
                    <h1 className="p-0 m-0 text-white">2D ART</h1>
                </div>
            </div>

            <div className="row p-5">
                <div className="col-sm-12 col-md-7 col-lg-7 col-12 p-0">
                    {/*                    <MyModal image={project.image} description={project.title} duration={0.5} />
 */}
                    <img className="w-100 mb-1" src={project.image} onClick={() => {
                        sessionStorage.setItem('SelectedImage', JSON.stringify(project))
                        navigate('/portfolio/project/image')
                    }} />

                    {otherImages(project)}
                </div>
                <div className="col mx-1 text-white d-flex flex-column" style={centerMiddle}>
                    <h1>
                        {project.title}
                    </h1>
                    <h5 className="text-start font-light">

                        {project.description}
                    </h5>
                </div>

            </div>

        </div >
    )

}

function MyModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let animationInitial = {
        opacity: 0,
    }
    let animate = {
        opacity: 1,
    }

    useEffect(() => {

    }, [])

    let duration = props.duration

    return (
        <div className="w-100">
            <img src={props.image} onClick={handleShow} className="w-100 mb-1" />
            <p className="p-0 mb-2 text-white">
                ao
            </p>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                size="lg"
            >
                <Modal.Body className='myModalBody text-center w-100 h-100 p-0'>
                    <img src={props.image} className="w-100 " onClick={handleClose} />
                </Modal.Body>
                <div style={{ position: 'absolute', bottom: '0', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} className="p-2 text-white text-center ">lol</div>
            </Modal>

        </div>

    );
}


export default Project