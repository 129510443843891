import Footer from "../component/footer"
import NavMenu from "../component/navMenu"
import React from 'react';

import vg from '../data/vg'


const videogames = vg
console.log(vg)

function Videogames() {

    return (
        <div style={{ backgroundColor: '#131419', overflow: '' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}

function Content() {

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    return (
        <div className="d-flex flex-column" style={{ minHeight: '70vh', overflowX: 'hidden', backgroundColor: '#37283D' }}>

            <div style={{ objectFit: 'cover', height: '10vh', backgroundImage: "url('./art/2d.jpg')" }}>
                <div style={centerMiddle}>
                    <h1 className="p-0 m-0 text-white">VIDEOGAMES</h1>
                </div>


                <VideogameList array={videogames} />

            </div>
        </div>
    )
}


function VideogameList(props) {
    console.log(props.array.length)

    let getVideogames = () => {
        let array = []
        for (let i = 0; i < props.array.length; i++) {
            array.push(<Videogame vg={props.array[i]} />)
        }
        return array
    }

    return (
        <div className="d-flex justify-content-center">
            <div className="d-flex flex-column"  >
                {getVideogames().map(vid => vid)}
            </div>
        </div>

    )
}

function Videogame(props) {
    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    };
    return (
        <>
            <a target="_blank" href={props.vg.url} className="d-flex my-4 text-decoration-none " style={{ height: '35vh', borderStyle: 'solid', borderWidth: '0px', borderColor: 'white', cursor: 'pointer' }} >
                <img className="h-100 p-2" src={props.vg.image} />
                <div className="p-5 text-white text-start" style={centerMiddle}>
                    <div className="d-flex flex-column">
                        <h1>{props.vg.title}</h1>
                        <p>
                            {props.vg.description}
                        </p>
                    </div>

                </div>
            </a >
        </>
    )
}
//   <div className="col" style={{ backgroundImage: 'url(./art/luca_pfp.png)', backgroundPosition: 'center', backgroundSize: 'cover' }}

export default Videogames