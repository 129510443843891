import Footer from "../component/footer"
import NavMenu from "../component/navMenu"
import React from 'react';
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import Images from "../data/images3d"


const images = Images


function Art3d() {

    return (
        <div style={{ backgroundColor: '#131419', overflow: '' }} className='App'>
            <NavMenu />
            <Content />
            <Footer />
        </div>
    )
}
function Content() {

    const centerMiddle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    };

    const [filter, setFilter] = useState('All')

    let getFilteredImages = () => {
        let array = []

        console.log(array)

        if (filter == 'All' || filter == '') {
            return images
        } else {
            images.map(img => {
                img.filter.map(fil => {
                    if (fil == filter) {
                        array.push(img)
                    }
                })
            })
            return array
        }
    }

    return (
        <div className="d-flex flex-column" style={{ minHeight: '70vh', overflowX: 'hidden', backgroundColor: '#37283D' }}>

            <div style={{ objectFit: 'cover', height: '10vh', backgroundImage: "url('./art/2d.jpg')" }}>
                <div style={centerMiddle}>
                    <h1 className="p-0 m-0 text-white">3D ART</h1>
                </div>
            </div>

            <ButtonGroup callback={setFilter} />

            <Gallery array={getFilteredImages()} />
        </div>
    )
}

function ButtonGroup(props) {
    return (
        <div className="ButtonGroup py-3">
            <input defaultChecked className="FilterRadio" id='filter5' type="radio" name='filter' onChange={() => props.callback('All')} />
            <label className="FilterLabel px-4 py-1 m-0" for='filter5'>ALL</label>

            <input className="FilterRadio" id='filter1' type="radio" name='filter' onChange={() => props.callback('PROPS')} />
            <label className="FilterLabel px-4 py-1 m-0" for='filter1'>PROPS</label>

            <input className="FilterRadio" id='filter2' type="radio" name='filter' onChange={() => props.callback('ENVIRONMENTS')} />
            <label className="FilterLabel px-4 py-1 m-0" for='filter2'>ENVIRONMENTS</label>

            <input className="FilterRadio" id='filter3' type="radio" name='filter' onChange={() => props.callback('CHARACTERS')} />
            <label className="FilterLabel px-4 py-1 m-0" for='filter3'>CHARACTERS</label>

            <input className="FilterRadio" id='filter4' type="radio" name='filter' onChange={() => props.callback('RENDERS')} />
            <label className="FilterLabel px-4 py-1 m-0" for='filter4'>RENDERS</label>
        </div >
    )
}

function Gallery(props) {

    const navigate = useNavigate()

    let grid = function () {
        let colonna = 0
        let array0 = []
        let array1 = []
        let array2 = []
        let array3 = []
        let choseColumn = function (i, img) {
            switch (i) {
                case 0:
                    array0.push(
                        (<img className="w-100 p-1" onClick={() => {
                            sessionStorage.setItem('selectedProject', JSON.stringify(img))
                            navigate('/portfolio/project')
                        }} src={img.image} />)
                    )
                    break
                case 1:
                    array1.push(
                        (<img className="w-100 p-1" onClick={() => {
                            sessionStorage.setItem('selectedProject', JSON.stringify(img))
                            navigate('/portfolio/project')
                        }} src={img.image} />))
                    break
                case 2:
                    array2.push(
                        (<img className="w-100 p-1" onClick={() => {
                            sessionStorage.setItem('selectedProject', JSON.stringify(img))
                            navigate('/portfolio/project')
                        }} src={img.image} />))
                    break
                case 3:
                    array3.push(
                        (<img className="w-100 p-1" onClick={() => {
                            sessionStorage.setItem('selectedProject', JSON.stringify(img))
                            navigate('/portfolio/project')
                        }} src={img.image} />))
                    break
            }
        }
        for (let i = 0; i < props.array.length; i++) {
            choseColumn(colonna, props.array[i])
            colonna++
            if (colonna > 3) colonna = 0
        }
        let output = (
            <div className="row">
                <div className="col p-0">
                    {array0.map(data => data)}
                </div>
                <div className="col p-0">
                    {array1.map(data => data)}
                </div>
                <div className="col p-0">
                    {array2.map(data => data)}
                </div>
                <div className="col p-0">
                    {array3.map(data => data)}
                </div>
            </div>
        )
        return output
    }

    return (
        <div className="px-5">
            {grid()}
        </div>
    )
}

export default Art3d